import Vue from 'vue'
import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_MOODLE_API_BASE_URL,
    params: {
        moodlewsrestformat: 'json'
    }
})

export const MoodleApi = {

    login: async (username: string, password: string) => {
        const response = await axiosInstance.get('/login/token.php', {
            params:{
                username: username,
                password: password,
                service: 'moodle_mobile_app'
            }
        })
        return response.data
    },

    get: async (wsfunction: string, params: {}) => {
        const token = Vue.prototype.$customStore.getters['login/GET_TOKEN']
        if (token === null) {
            console.log('Error: User not logged in!')
        }
        const response = await axiosInstance.get('/webservice/rest/server.php', {
            params: Object.assign({
                wstoken: token,
                wsfunction: wsfunction
            }, params)
        })
        return response.data
    },

    post: async (wsfunction: string, params: {}, formData: any) => {
        const token = Vue.prototype.$customStore.getters['login/GET_TOKEN']
        if (token === null) {
            console.log('Error: User not logged in!')
        }
        const response = await axiosInstance.post('/webservice/rest/server.php', formData, {
            params: Object.assign({
                wstoken: token,
                wsfunction: wsfunction
            }, params)
        })
        return response.data
    },

    upload: async (params: {}, itemId: number = 0) => {
        const token = Vue.prototype.$customStore.getters['login/GET_TOKEN']
        if (token === null) {
            console.log('Error: User not logged in!')
        }
        const response = await axiosInstance.post('/webservice/upload.php', params, {
            params: {
                token: token,
                filearea: 'draft',
                itemid: itemId
            }
        })
        return response.data
    },

    getFileDownloadUrl: (
        context_id: number,
        component: string,
        file_area: string,
        item_id: number,
        file_path: string,
        file_name: string
    ) => {
        const token = Vue.prototype.$customStore.getters['login/GET_TOKEN']
        if (token === null) {
            console.log('Error: User not logged in!')
        }

        return process.env.VUE_APP_MOODLE_API_BASE_URL
            + '/webservice/rest/server.php'
            + '?wstoken=' + token
            + '&wsfunction=local_lumaconi_download_draft_file'
            + '&context_id=' + context_id
            + '&component=' + component
            + '&file_area=' + file_area
            + '&item_id=' + item_id
            + '&file_path=' + file_path
            + '&file_name=' + file_name
            + '&moodlewsrestformat=json'
    },
}